import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
          }
        }
      }
    }
  `);

  const { social } = data.site.siteMetadata;

  return (
    <footer className="flex items-center justify-center mt-20 py-4 sm:py-8 bg-purple-700 text-white">
      <div className="flex items-center justify-between w-full max-w-2xl mx-auto px-4">
        <div className="flex-shrink-0">© {new Date().getFullYear()}</div>
        <div>
          <a
            href={`https://twitter.com/${social.twitter}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          {' • '}
          <a
            href={`https://github.com/${social.github}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
          {' • '}
          <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
            RSS
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
