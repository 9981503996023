import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title } = data.site.siteMetadata;

  // TODO: add nav links
  const nav = [];

  return (
    <nav className="flex items-center justify-center py-4 sm:py-8 bg-purple-500">
      <div className="flex items-center justify-between w-full max-w-2xl mx-auto px-4">
        <h1 className="flex-shrink-0 mr-4 text-xl text-white font-medium">
          <Link to="/">{title}</Link>
        </h1>
        <div>
          {nav.map(({ path, name }) => (
            <Link
              className="inline-block ml-4 px-3 py-1 rounded-sm text-purple-100 hover:bg-purple-600 transition-colors duration-200 ease-in-out"
              activeClassName="bg-purple-600"
              key={path}
              to={path}
            >
              {name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Header;
