import React from 'react';

import Header from './header';
import Footer from './footer';

const Layout = ({ location, children }) => {
  return (
    <>
      <Header location={location} />
      <main className="max-w-2xl mx-auto my-6 px-4">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
